import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6235c91c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeliveryDatePickerInner = _resolveComponent("DeliveryDatePickerInner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_component_DeliveryDatePickerInner, _mergeProps(_ctx.$attrs, {
      key: _ctx.componentKey,
      modelValue: _ctx.modelValue,
      shipmentId: _ctx.shipmentId,
      "is-mobile": _ctx.isMobile,
      isCompact: _ctx.isCompact,
      onDatePickerOpen: _ctx.onDatePickerOpened,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.$emit('update:modelValue', value))
    }), null, 16, ["modelValue", "shipmentId", "is-mobile", "isCompact", "onDatePickerOpen"])),
    ( _ctx.isMobile && _ctx.isDatePickerOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 64))
}