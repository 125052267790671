import { AxiosResponse } from 'axios'
import { generalServiceV1API } from '@/store/api-instance'
import { IDocumentResponse, IDocumentTypeResponse } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { IBulkDownload } from '@/store/modules/documents/interfaces/IBulkDocument'

export const documentsAPI = {
  async getDocuments (dto: IDocumentRequest): Promise<IDocumentResponse> {
    const response: AxiosResponse<IDocumentResponse> = await generalServiceV1API.get('documents/GetAllDocuments', {
      params: dto
    })
    return response.data
  },
  async getDocumentTypes (): Promise<IDocumentTypeResponse> {
    const response: AxiosResponse<IDocumentTypeResponse> = await generalServiceV1API.get(
      'documents/GetAdrDeliveryDocumentsType',
      {}
    )
    return response.data
  },
  async fetchBulkDocuments (dto: IBulkDownload[]): Promise<Blob> {
    const response: AxiosResponse = await generalServiceV1API.post<Blob>('documents/GetDownloadZipDocumentBulk', dto, {
      responseType: 'blob'
    })
    return response.data
  },
  async getDocumentLink (documentId: string, documentType: string): Promise<string> {
    const response: AxiosResponse<string> = await generalServiceV1API.get('documents/GetDocumentExternalLinkAsyncV2', {
      params: { documentId, documentType }
    })
    return response.data
  }
}
