
import { defineComponent, PropType } from 'vue'
import { mapGetters, mapState } from 'vuex'
import AsahiTabs from '@/components/library/AsahiTabs.vue'
import CustomButton from '@/components/library/AsahiBsCustomButton.vue'
import AsahiLogoNew from '@/components/library/assets/AsahiLogoNew.vue'
import AsahiProgressBar from '@/components/library/assets/AsahiProgressBar.vue'
import AsahiUserIconAlt from '@/components/library/assets/AsahiUserIconAlt.vue'
import locationwhite from '@/assets/icons/library/locationwhite.svg'
import locationgold from '@/assets/icons/library/locationgold.svg'
import AsahiFavoriteIconAlt from '@/components/library/assets/AsahiFavoriteIconAlt.vue'
import { Collapse, Modal } from 'bootstrap'
import AsahiBsSearchProducts from '@/components/library/AsahiBsSearchProducts.vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import ResizeObserver from '@/components/ResizeObserver.vue'
import 'vue3-carousel/dist/carousel.css'
import { FilesActionType } from '@/store/modules/files/interfaces/action-type'
import AsahiBsSearchMarketingMaterials from '@/components/library/AsahiBsSearchMarketingMaterials.vue'
import { ArticlesActionType } from '@/store/modules/articles/interfaces/action-type'
import AsahiBsSearchNewsAndArticles from '@/components/library/AsahiBsSearchNewsAndArticles.vue'
import { SupportActionType } from '@/store/modules/support/interfaces/action-type'
import AsahiBsSearchSupport from '@/components/library/AsahiBsSearchSupport.vue'
import AsahiLoaderCard from '@/components/library/AsahiLoaderCard.vue'
import { hasPermission } from '@/utils/permissions'
import { IPortalHardCodeLink } from '@/store/modules/links/interfaces/IPortalHardCodeLink'
import LoaderSpinner from '@/components/common/LoaderSpinner.vue'
import AngleLeftRightIcon from '@/components/library/icons/AngleLeftRightIcon.vue'
import AsahiHistoryIcon from '@/components/library/assets/AsahiHistoryIcon.vue'

interface CartItem {
  id: string;
  product: {
    name: string;
    image: {
      url: string;
    };
    sku: number
    number_of_products_on_layer: number
  };
  quantity_total: number;
  number_of_layer: number,
  number_of_pallet: number
}
interface MenuItem {
  label: string;
  name: string;
  isActive?: boolean;
  icon?: string;
  href?: string | '';
  children?: MenuItem[];
  title?: string;
}
interface BrandLink {
  label: string;
  link: string;
  target: string;
  order: string;
}
interface FooterLink {
  name: string,
  label: string
  href?: string
  link?: string
  target?: string
  isActive?: boolean
}

interface FooterSection {
  name?: string
  title?: string
  label?: string
  links?: FooterLink[]
  children?: FooterLink[]
}

interface HeaderData {
  label: string;
  children: any[];
  name: string;
}

interface User {
  permissionSchema: { shipToAddress: any, companyName: string, shipToId: string }[];
}

export default defineComponent({
  name: 'AsahiBsNav',

  props: {
    headerData: {
      type: Array as PropType<HeaderData[]>,
      required: true
    },
    footerData: {
      type: Array as () => FooterSection[],
      required: true
    },
    rightMenu: {
      type: Array as PropType<FooterLink[]>,
      required: false
    },
    cartItem: {
      type: Array as PropType<CartItem[]>,
      required: true
    },
    favoriteItem: {
      type: Number,
      required: false
    },
    cartNumber: {
      type: Number
    },
    user: {
      type: Object as PropType<User>,
      required: true
    },
    catalogPermission: {
      type: Boolean
    },
    brandData: {
      type: Array as PropType<BrandLink[]>,
      required: true
    }
  },
  data () {
    return {
      showOnHover: true,
      locationgold,
      locationwhite,
      searchInputActive: false,
      searchActive: false,
      openCart: false,
      searchValue: '',
      searchKeyword: '',
      searchTimer: 0,
      CompanyName: localStorage.getItem('selectedCompanyName') || this.user.permissionSchema[0].companyName,
      tabs: [
        { name: 'products', label: this.$t('Products').toUpperCase(), component: AsahiBsSearchProducts },
        { name: 'marketing-materials', label: this.$t('Marketing materials').toUpperCase(), component: AsahiBsSearchMarketingMaterials },
        { name: 'news-and-articles', label: this.$t('News and Articles').toUpperCase(), component: AsahiBsSearchNewsAndArticles },
        { name: 'support', label: this.$t('Support').toUpperCase(), component: AsahiBsSearchSupport }
      ],
      currentTab: 0,
      searchHistory: JSON.parse(sessionStorage.getItem('search_history') as string) || [],
      loading: false,
      clientWidth: 0,
      currentCarouselConfig: { itemsToScroll: 4 },
      currentSlide: 0,
      estimatedSlidesCount: 0,
      pageCount: 0,
      currentPage: 1,
      settings: {
        itemsToShow: 1,
        snapAlign: 'start'
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          itemsToScroll: 1,
          snapAlign: 'start'
        },
        390: {
          itemsToShow: 2.5,
          itemsToScroll: 2,
          snapAlign: 'start'
        },
        768: {
          itemsToShow: 4,
          itemsToScroll: 4,
          snapAlign: 'start'
        },
        1920: {
          itemsToShow: 6,
          itemsToScroll: 6,
          snapAlign: 'start'
        }
      },
      footerSection: [] as FooterLink[],
      openLanguage: false,
      combinedFooterData: [] as FooterSection[],
      isLoading: false
    }
  },
  components: {
    AsahiHistoryIcon,
    AngleLeftRightIcon,
    AsahiFavoriteIconAlt,
    AsahiUserIconAlt,
    AsahiLogoNew,
    AsahiProgressBar,
    AsahiTabs,
    CustomButton,
    AsahiLoaderCard,
    Carousel,
    Slide,
    Pagination,
    ResizeObserver,
    LoaderSpinner
  },
  computed: {
    ...mapState('basket', ['id', 'query', 'items', 'paymentMethod', 'showToastDetails']),
    ...mapGetters('basket', ['progressBarMessage', 'progressBarSuccess', 'showProgressBarMessage']),
    ...mapState('catalog', ['products', 'alignment']),
    ...mapGetters('main', ['getPermissions']),

    portalHardCodeLink (): IPortalHardCodeLink[] {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    },
    portalPageMenuLink () {
      return this.$store.state.links.portalPageMenuLink?.items ?? []
    },
    currentUser () {
      return this.$store.state.auth.user
    },
    allPermission () {
      return this.currentUser.allAvailablePermission
    },

    marketingMaterialsFiles () {
      return this.filesStore.marketingMaterials.results
    },
    isTruckAvailable () {
      return this.query && this.query.progress_bar_data && typeof this.query.progress_bar_data.isFullTruck !== 'undefined'
    },

    isTruck () {
      if (this.isTruckAvailable) {
        return this.query.progress_bar_data.isFullTruck
      }
      return false
    },
    getSelectedShipToDetails () {
      const shipToId = this.query.ship_to_id || localStorage.getItem('shipToId')
      const data = this.user.permissionSchema.filter((permission: any) => permission.shipToId === shipToId)
      return data
        ? {
            shipToId: data[0]?.shipToId,
            shipToName: data[0]?.companyName,
            shipToAddress: (data[0]?.shipToAddress)
          }
        : {}
    },
    shipToName () {
      const shipToId = this.query.ship_to_id || localStorage.getItem('shipToId')
      return this.user.permissionSchema.find((permission: any) => permission.shipToId === shipToId)
    },
    shipToList () {
      return this.user.permissionSchema.filter((permission: any) => permission.rpDetails.some((role: any) => role.permission.includes('Catalogue')))
        .map(item => ({
          label: item.companyName + ', ' + (item.shipToAddress).toLowerCase(),
          value: item.shipToId,
          text: item.companyName,
          longText: (item.shipToAddress).toLowerCase()
        }))
    },
    filesStore () {
      return this.$store.state.files
    },
    hasProductsData () {
      return this.products.length > 0
    },
    hasMarketingMaterialsData () {
      const marketingMaterial = this.filesStore && this.filesStore.marketingMaterials && this.filesStore.marketingMaterials.results
      return marketingMaterial && marketingMaterial.length > 0
    },
    hasNewsData () {
      const newArticle = this.$store.state.articles.articles && this.$store.state.articles.articles.results
      return newArticle && newArticle.length > 0
    },
    hasSupportData () {
      const faqPages = this.$store.state.support.faqPages
      const kbsAsync = this.$store.state.support.newAllKbsAsync
      return (faqPages && faqPages.length > 0) || (kbsAsync && kbsAsync.length > 0)
    },
    visibleTabs () {
      return this.tabs.map(tab => {
        switch (tab.name) {
          case 'products':
            return { ...tab, isVisible: this.hasProductsData }
          case 'marketing-materials':
            return { ...tab, isVisible: this.hasMarketingMaterialsData }
          case 'news-and-articles':
            return { ...tab, isVisible: this.hasNewsData }
          case 'support':
            return { ...tab, isVisible: this.hasSupportData }
          default:
            return { ...tab, isVisible: true }
        }
      }).filter(tab => tab.isVisible)
    },
    footerLinksBrands () {
      let brandLinks

      if (this.currentUser.country === 'SVK') {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands sk' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      } else {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands cz' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      }
      return [
        {
          title: 'brands'
        },
        ...brandLinks
      ]
    }
  },
  methods: {

    closeModal () {
      const menuModalNav = document.getElementById('searchModalNav')
      if (menuModalNav) {
        const modal = Modal.getInstance(menuModalNav) || new Modal(menuModalNav)
        if (modal) {
          modal.hide()
          this.searchActive = false
        }
      }
    },

    isExternal (url:string) {
      return url?.includes('http')
    },

    goToFavourites () {
      this.$router.push({ path: '/catalog/favorites', replace: true })
    },

    hideModal () {
      const menuModalNav = document.getElementById('menuModalNav')
      Modal.getInstance(menuModalNav).hide()
    },

    toggleAccordion (event: any, index: number) {
      if (event?.target?.tagName !== 'A') {
        const menuCollapseNav = document.getElementById('asahi-bs-nav-accordion-collapse-' + index)
        Collapse.getOrCreateInstance(menuCollapseNav).toggle()
        event?.target?.classList.toggle('collapsed')
        event?.target?.setAttribute('aria-expanded', event?.target?.classList.contains('collapsed') ? 'false' : 'true')
      }
    },
    async removeProductFromCart (productId: string) {
      try {
        const store = this.$store // Assicurati che lo store sia corretto
        if (store.state.basket?.id && productId) {
          await store.dispatch('basket/REMOVE_ITEM', {
            cart_id: store.state.basket.id,
            cart_item_id: productId
          }, { root: true })
        }
      } catch (error) {
        console.error('Failed to remove product from cart:', error)
      }
    },
    async removeItemInner (id: string) {
      await this.$store.dispatch('basket/REMOVE_ITEM', { cart_id: this.id, cart_item_id: id }, { root: true })
    },
    async removeAllCartItems () {
      await Promise.all(this.items?.map((product: any) => this.removeItemInner(product.id)))
    },
    updateCompanyName (selectedCompanyName: any) {
      this.CompanyName = selectedCompanyName
      localStorage.setItem('selectedCompanyName', selectedCompanyName)
    },
    getRoutingPath (children: any, name: string) {
      let routingPath = ''
      if (name === 'products_catalogue') {
        routingPath = '/catalog/products'
      } else {
        routingPath = children?.length > 0 ? children[0].href : ''
      }
      return routingPath
    },
    async searchProducts (keyword?: string) {
      this.loading = true
      try {
        await this.$store.dispatch('catalog/FETCH_PRODUCTS', {
          search: this.searchValue?.length >= 3 ? this.searchValue : (keyword || ''),
          currentPage: 1
        }, { root: true })
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    searchMarketingMaterials (keyword?: string) {
      this.loading = true
      this.$store.dispatch(
        FilesActionType.FETCH_MATERIALS_SEARCH,
        {
          searchTextTitle: this.searchValue?.length >= 3 ? this.searchValue : (keyword || '')
        },
        { root: true }
      )
      this.loading = false
    },
    searchNewsAndArticles () {
      this.loading = true
      this.$store.dispatch(
        ArticlesActionType.FETCH_ARTICLES,
        {
          searchTextTitle: this.searchValue,
          isLoggedIn: this.$store.state.auth.user.isLoggedIn
        },
        { root: true }
      )
      this.loading = false
    },
    searchSupport () {
      this.loading = true
      this.$store.dispatch(
        SupportActionType.FETCH_NEW_ALL_KBS_ASYNC,
        {
          searchTextTitle: this.searchValue
        },
        { root: true }
      )
      this.$store.dispatch(
        SupportActionType.FETCH_NEW_ALL_FAQ_PAGES,
        {
          searchTextTitle: this.searchValue
        },
        { root: true }
      )
      this.loading = false
    },
    storeSearchHistory () {
      const searchHistoryStored = JSON.parse(sessionStorage.getItem('search_history') as string) || []
      searchHistoryStored.unshift(this.searchValue)
      const newSearchHistory = [...new Set(searchHistoryStored)]?.slice(0, 3)
      this.searchHistory = newSearchHistory
      sessionStorage.setItem('search_history', JSON.stringify(newSearchHistory))
    },
    onSearchOpen () {
      if (!this.searchActive) {
        this.searchActive = true
        this.initSearch()
      }
    },
    onSearchClose () {
      this.searchActive = false
      this.onSearchReset()
    },
    resetQuery () {
      const queries = {
        sort: { catalog_product_name: 'ASC' },
        search: '',
        category_uid: [],
        brand: [],
        sub_brand: [],
        filter_packaging: [],
        type: [],
        material_type: [],
        price: [],
        volume: []
      }
      this.$store.commit('catalog/SET_QUERIES', {
        ...queries
      }, { root: true })
    },
    initSearch () {
      if (this.searchHistory?.length > 0) {
        this.searchKeyword = this.searchHistory[0]
      } else {
        this.searchKeyword = ''
      }
      this.searchProducts(this.searchKeyword)
      this.searchMarketingMaterials(this.searchKeyword)
    },

    resetSearch () {
      this.searchKeyword = ''
      this.resetQuery()
      this.searchProducts(this.searchKeyword)
      this.searchMarketingMaterials(this.searchKeyword)
    },
    doSearch () {
      const tabName = this.tabs[this.currentTab].name.toLowerCase().replace(' ', '-')
      if (tabName === 'products') {
        this.searchProducts()
      } else if (tabName === 'marketing-materials') {
        this.searchMarketingMaterials()
      } else if (tabName === 'news-and-articles') {
        this.searchNewsAndArticles()
      } else if (tabName === 'support') {
        this.searchSupport()
      }
    },
    doSearchOnEnter () {
      this.searchProducts()
      this.searchMarketingMaterials()
      this.searchNewsAndArticles()
      this.searchSupport()
    },
    redoSearch (keyword: string) {
      this.searchValue = keyword
      this.doSearch()
    },
    onSearchType () {
      clearTimeout(this.searchTimer)
      if (this.searchValue?.length >= 3) {
        this.searchTimer = setTimeout(() => {
          this.storeSearchHistory()
          this.doSearch()
        }, 1500)
      } else {
        this.initSearch()
      }
    },
    onSearchEnter () {
      clearTimeout(this.searchTimer)
      this.storeSearchHistory()
      this.doSearch()
    },
    onSearchReset () {
      this.searchValue = ''
      this.resetSearch()
    },
    onTabChanged (tabIndex: number) {
      this.currentTab = tabIndex
    },
    queries () {
      return this.$store.state.catalog.queries
    },
    updateSearchValue (value: string) {
      const query = {
        ...this.queries,
        search: value
      }
      this.$store.commit('catalog/SET_QUERIES', query, { root: true })
      this.$store.dispatch('catalog/FETCH_PRODUCTS', query, { root: true })
    },
    closeLangAccordion () {
      const myCollapse = document.getElementById('asahi-bs-footer-accordion-collapse-language')
      if (myCollapse?.classList.contains('show')) {
        Collapse(myCollapse, {
          toggle: true
        })
      }
    },

    openLanguageDropdown () {
      this.openLanguage = !this.openLanguage
    },
    getFullLanguageName (code: string): string {
      const languageMap: { [key: string]: string } = { en: 'English', cs: 'Česky', sk: 'Slovensky' }
      return languageMap[code] || code
    },
    linkBrands () {
      let brandLinks: { label: string, link: string, target: string, order: number }[]

      if (this.currentUser.country === 'SVK') {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands sk' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      } else {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands cz' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      }

      return brandLinks
    },
    combineFooterData () {
      const orderedData: FooterSection[] = []
      const catalogSection = (this.portalPageMenuLink as MenuItem[]).find(section => section.name === 'products_catalogue')
      const ordersSection = (this.portalPageMenuLink as MenuItem[]).find(section => section.name === 'Orders')
      const otherSections = (this.portalPageMenuLink as MenuItem[]).filter(section => section.name !== 'Orders' && section.name !== 'Create an order' && section.name !== 'products_catalogue')
      const myAccountSection = this.newMenu().find(section => section.name === 'profile')

      if (catalogSection) {
        orderedData.push(catalogSection)
      }

      if (ordersSection) {
        orderedData.push(ordersSection)
      }

      const footerBrandLinks = this.linkBrands().map(brand => ({
        name: brand?.label || '',
        label: brand?.label || '',
        href: brand?.link || '',
        target: brand?.target || ''
      }))
      if (footerBrandLinks.length > 0) {
        orderedData.push({
          name: 'brands',
          label: this.$t('Our Brands'),
          children: footerBrandLinks
        })
      }

      otherSections.forEach((section) => {
        orderedData.push(section)
      })

      if (myAccountSection) {
        orderedData.push(myAccountSection)
      }

      this.combinedFooterData = orderedData
    },
    newMenu (): FooterSection[] {
      return [
        {
          label: this.$t('My account'),
          name: 'profile',
          children: this.combinedFooterSection()
        }
      ]
    },
    combinedFooterSection () {
      return [
        ...this.footerSection,
        {
          name: 'Log Out',
          label: this.$t('Log out'),
          href: '', // DO NOT ADD HREF HERE IT WILL MAKE PERFORMANCE ISSUE
          isActive: true
        }
      ]
    },
    getFooterChildren () {
      const footerLinks: FooterLink[] = [

        {
          name: 'My Profile',
          label: this.$t('My Profile'),
          href: '/my-profile/details',
          isActive: true
        },
        {
          name: 'My Documents',
          label: this.$t('My Documents'),
          href: '/my-documents',
          isActive: true
        },
        {
          name: 'Reporting',
          label: this.$t('Reporting'),
          href: '/reporting',
          isActive: true
        },
        {
          name: 'Contracts',
          label: this.$t('Contracts'),
          href: '/contracts',
          isActive: true
        }

      ]
      this.footerSection = footerLinks.filter((f) => {
        return hasPermission(this.allPermission, this.getPermissions(), f.name)
      })
    },
    async handleLogout () {
      await this.$store.dispatch('logout')
      this.isLoading = false
      this.$navigate('/login')
    },
    async userLogout () {
      this.isLoading = true
      sessionStorage.removeItem('uid')
      sessionStorage.removeItem('imp_usr')
      sessionStorage.removeItem('isFromFavoritePage')
      const params = {
        UID: this.currentUser.UID,
        callback: this.handleLogout
      }
      await window.gigya.accounts.logout(params)
    },
    handleMenuClick (item: FooterLink) {
      if (item.name === 'Log Out') {
        this.userLogout()
        this.hideModal()
      } else if (
        item?.name === 'Academy' ||
        item?.name === 'Pub Menu'
      ) {
        window.gigya.fidm.saml.initSSO({
          spName: this.getSPName(item?.name),
          redirectURL: item.href
        })
      }
    },

    getSPName (name: string) {
      let spName = ''
      if (name === 'Academy') {
        spName = this.currentUser.country === 'SVK' ? 'skELearning' : 'eLearning'
      }
      if (name === 'Pub Menu') {
        spName = this.currentUser.country === 'SVK' ? 'skPubMenu' : 'pubMenu'
      }
      return spName
    },
    init () {
      this.$emit('pageChange', {
        total: this.pageCount,
        currentPage: this.currentPage
      })
    },
    prev () {
      (this.$refs.productsCarousel as any).prev()
      this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1
      this.$emit('pageChange', {
        total: this.pageCount,
        currentPage: this.currentPage
      })
    },
    next () {
      (this.$refs.productsCarousel as any).next()
      this.currentPage = this.currentPage < this.pageCount ? this.currentPage + 1 : this.pageCount
      this.$emit('pageChange', {
        total: this.pageCount,
        currentPage: this.currentPage
      })
    },
    handleInit () {
      this.currentSlide = 0
      const config = (this.$refs.productsCarousel as any)?.data?.config
      this.currentCarouselConfig = config
      this.pageCount = this.getPageCount(this.products.length, config.itemsToScroll)
      this.estimatedSlidesCount = config.itemsToScroll * this.pageCount
      this.$emit('pageChange', {
        total: this.pageCount,
        currentPage: this.currentPage
      })
    },
    handleSlideStart ({ slidingToIndex }: { slidingToIndex: number }) {
      this.currentSlide = slidingToIndex
    },
    getPageCount (slidesCount: number, itemsToScroll: number) {
      let totalPages = 0
      if (slidesCount > 0) {
        totalPages = itemsToScroll > 0 ? Math.ceil(slidesCount / itemsToScroll) : 0
      }
      return totalPages
    },
    onResizePortal ({ height }: { height: number }) {
      this.clientWidth = document.querySelector('body')?.clientWidth || height
    }
  },
  mounted () {
    setTimeout(() => {
      this.linkBrands()
    }, 5000)

    this.combineFooterData()
    this.getFooterChildren()
    this.init()
    this.$store.commit('basket/SET_PROGRESS_BAR', this.progressBarMessage)
  },
  watch: {
    currentSlide (nextVal, prevVal) {
      const paginationEl: HTMLElement = this.$refs.pagination as HTMLElement
      if (nextVal > prevVal) {
        paginationEl.scrollLeft += 30
      } else {
        paginationEl.scrollLeft -= 30
      }
    },
    footerData: {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.length > 0) {
          this.getFooterChildren()
          this.combineFooterData()
          this.linkBrands()
        }
      }
    },
    footerLinksBrands: {
      deep: true,
      handler (newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.getFooterChildren()
          this.combineFooterData()
          this.linkBrands()
        }
      }
    },
    $route: {
      immediate: true,
      handler () {
        this.getFooterChildren()
        this.combineFooterData()
        this.linkBrands()
      }
    }
  }
})
