import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b038dd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "asahi-progress-bar",
  ref: "progressBar"
}
const _hoisted_2 = { class: "bottle-container rounded-2" }
const _hoisted_3 = { class: "d-flex align-items-center flex-column" }
const _hoisted_4 = { class: "d-flex gap-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "options-container-title" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "fw-normal"
}
const _hoisted_9 = { class: "options-container-title" }
const _hoisted_10 = { class: "options-container-message" }
const _hoisted_11 = {
  key: 1,
  class: "fw-normal"
}
const _hoisted_12 = { class: "options-container-title" }
const _hoisted_13 = { class: "options-container-message" }
const _hoisted_14 = {
  key: 2,
  class: "fw-normal"
}
const _hoisted_15 = { class: "options-container-title" }
const _hoisted_16 = { class: "options-container-message" }
const _hoisted_17 = { class: "d-flex align-items-center mt-1" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "d-flex gap-3" }
const _hoisted_20 = {
  key: 0,
  class: "fw-normal"
}
const _hoisted_21 = { class: "fw-bold" }
const _hoisted_22 = {
  key: 1,
  class: "fw-normal"
}
const _hoisted_23 = { class: "fw-bold" }
const _hoisted_24 = {
  key: 2,
  class: "fw-normal"
}
const _hoisted_25 = { class: "fw-bold" }
const _hoisted_26 = {
  key: 1,
  class: "asahi-progress-bar-message-no-bg d-flex gap-1 align-items-start w-100 fw-medium"
}
const _hoisted_27 = { class: "d-flex align-items-center marg-7" }
const _hoisted_28 = { class: "gap-3 w-100" }
const _hoisted_29 = { class: "row py-1" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "row py-1" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = {
  key: 0,
  class: "row py-1"
}
const _hoisted_34 = { class: "col-12 d-flex justify-content-between" }
const _hoisted_35 = { class: "fw-bold" }
const _hoisted_36 = {
  key: 1,
  class: "row py-1"
}
const _hoisted_37 = { class: "col-12 d-flex justify-content-between" }
const _hoisted_38 = { class: "fw-bold" }
const _hoisted_39 = { class: "row py-1" }
const _hoisted_40 = {
  key: 0,
  class: "col-12 d-flex justify-content-between"
}
const _hoisted_41 = { class: "fw-bold" }
const _hoisted_42 = {
  key: 1,
  class: "asahi-progress-bar"
}
const _hoisted_43 = { class: "w-100 rounded-2" }
const _hoisted_44 = {
  key: 0,
  class: "d-flex align-items-center flex-column"
}
const _hoisted_45 = { class: "d-flex gap-2 align-items-center" }
const _hoisted_46 = ["innerHTML"]
const _hoisted_47 = { class: "d-flex align-items-center mt-1" }
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = { class: "d-flex gap-3" }
const _hoisted_50 = {
  key: 0,
  class: "fw-normal"
}
const _hoisted_51 = { class: "fw-bold" }
const _hoisted_52 = {
  key: 1,
  class: "fw-normal"
}
const _hoisted_53 = { class: "fw-bold" }
const _hoisted_54 = {
  key: 2,
  class: "fw-normal"
}
const _hoisted_55 = { class: "fw-bold" }
const _hoisted_56 = { class: "d-flex align-items-start gap-1" }
const _hoisted_57 = ["innerHTML"]
const _hoisted_58 = ["innerHTML"]
const _hoisted_59 = { class: "asahi-compact-message" }
const _hoisted_60 = ["innerHTML"]
const _hoisted_61 = { class: "d-flex flex-column gap-3 mt-2" }
const _hoisted_62 = {
  key: 0,
  class: "d-flex justify-content-between"
}
const _hoisted_63 = { class: "fw-bold" }
const _hoisted_64 = {
  key: 1,
  class: "d-flex justify-content-between"
}
const _hoisted_65 = { class: "fw-bold" }
const _hoisted_66 = {
  key: 2,
  class: "d-flex justify-content-between"
}
const _hoisted_67 = { class: "fw-bold" }
const _hoisted_68 = { key: 3 }
const _hoisted_69 = {
  key: 0,
  class: "asahi-mini-container"
}
const _hoisted_70 = { class: "d-flex align-items-start gap-1" }
const _hoisted_71 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AllertCart = _resolveComponent("AllertCart")!
  const _component_SuccessCart = _resolveComponent("SuccessCart")!
  const _component_AsahiProgress = _resolveComponent("AsahiProgress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.messageType != 'mini' && _ctx.messageType != 'icon-only' && !_ctx.emptiesDeliveryMethod())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.progressBarMessage?.hasError)
                  ? (_openBlock(), _createBlock(_component_AllertCart, {
                      key: 0,
                      "height-value": 17,
                      "width-value": 14
                    }))
                  : _createCommentVNode("", true),
                (_ctx.progressBarMessage?.hasSucccess)
                  ? (_openBlock(), _createBlock(_component_SuccessCart, {
                      key: 1,
                      "height-value": 17,
                      "width-value": 14
                    }))
                  : _createCommentVNode("", true),
                (_ctx.messageType === 'header')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "fw-normal",
                      innerHTML: _ctx.progressBarMessage.title
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_AsahiProgress, {
                "is-white": _ctx.isWhite,
                barHeight: "4",
                percentage: _ctx.getFillPercentage(),
                success: _ctx.progressBarMessage?.hasSucccess === true
              }, null, 8, ["is-white", "percentage", "success"]),
              _createElementVNode("div", {
                class: _normalizeClass(["options-container py-3", {'in-homepage' : _ctx.isHomePge}])
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('Order Capacity')), 1),
                _createElementVNode("div", {
                  class: "d-flex align-items-center gap-1 fw-bold options-container-message",
                  innerHTML: _ctx.progressBarMessage.subtitle
                }, null, 8, _hoisted_7),
                (_ctx.progressBar?.isFullTruck)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('Weight')), 1),
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.progressBar?.iTotalCartWeight) + " Kg/ " + _toDisplayString(_ctx.progressBar?.iMaximumOrderWeight) + " Kg ", 1)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.progressBar?.isFullTruck)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('Monetary Value')), 1),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.formatCurrency(_ctx.grandTotalYourPrice)) + " / " + _toDisplayString(_ctx.formatCurrency(_ctx.maxOrderCash)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isWHSLUser && (_ctx.isEmptyTruck || _ctx.progressBar?.isFullTruck ))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('Number of pallets')), 1),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.totalCartPallet) + " / " + _toDisplayString(_ctx.minimumOrderPallett || 20), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ], 512), [
            [_vShow, _ctx.messageType === 'header']
          ]),
          (_ctx.messageType === 'long')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["asahi-progress-bar-message d-flex gap-1 align-items-start", _ctx.messageColorClass])
              }, [
                _createElementVNode("div", _hoisted_17, [
                  (_ctx.progressBarMessage?.hasError)
                    ? (_openBlock(), _createBlock(_component_AllertCart, {
                        key: 0,
                        "height-value": 24,
                        "width-value": 24
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.progressBarMessage?.hasSucccess)
                    ? (_openBlock(), _createBlock(_component_SuccessCart, {
                        key: 1,
                        "height-value": 24,
                        "width-value": 24
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    class: "d-flex align-items-center gap-1 fw-normal",
                    innerHTML: this.progressBarMessage.subtitle
                  }, null, 8, _hoisted_18),
                  _createElementVNode("div", _hoisted_19, [
                    (_ctx.progressBar?.isFullTruck)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Weight')) + " ", 1),
                          _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.progressBar?.iTotalCartWeight) + " Kg/ " + _toDisplayString(_ctx.progressBar?.iMaximumOrderWeight) + " Kg", 1)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.progressBar?.isFullTruck && !_ctx.isEmptyTransportType)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Monetary Value')) + " ", 1),
                          _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.formatCurrency(_ctx.grandTotalYourPrice)) + " / " + _toDisplayString(_ctx.formatCurrency(_ctx.maxOrderCash)), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.isWHSLUser && (_ctx.isEmptyTruck || _ctx.progressBar?.isFullTruck ))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Number of pallets')) + " ", 1),
                          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.totalCartPallet) + " / " + _toDisplayString(_ctx.minimumOrderPallett || 20), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.messageType === 'mobile-long')
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  (_ctx.progressBarMessage?.hasError)
                    ? (_openBlock(), _createBlock(_component_AllertCart, {
                        key: 0,
                        "height-value": 20,
                        "width-value": 20
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.progressBarMessage?.hasSucccess)
                    ? (_openBlock(), _createBlock(_component_SuccessCart, {
                        key: 1,
                        "height-value": 20,
                        "width-value": 20,
                        "is-white": true
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", {
                      class: "col-12 asahi-compact-message",
                      innerHTML: _ctx.progressBarMessage?.subtitle
                    }, null, 8, _hoisted_30)
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", {
                      class: "col-12 asahi-compact-message",
                      innerHTML: _ctx.progressBarMiniMessage?.title
                    }, null, 8, _hoisted_32)
                  ]),
                  _createVNode(_component_AsahiProgress, {
                    "is-white": _ctx.isWhite,
                    barHeight: "4",
                    percentage: _ctx.getFillPercentage(),
                    success: _ctx.progressBarMessage?.hasSucccess === true
                  }, null, 8, ["is-white", "percentage", "success"]),
                  (_ctx.progressBar?.isFullTruck)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Weight')) + " ", 1),
                          _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.progressBar?.iTotalCartWeight) + " Kg/ " + _toDisplayString(_ctx.progressBar?.iMaximumOrderWeight) + " Kg", 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.progressBar?.isFullTruck && !_ctx.isEmptyTransportType)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                        _createElementVNode("div", _hoisted_37, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Monetary Value')) + " ", 1),
                          _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.formatCurrency(_ctx.grandTotalYourPrice)) + "/" + _toDisplayString(_ctx.formatCurrency(_ctx.maxOrderCash)), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_39, [
                    (_ctx.isWHSLUser && (_ctx.isEmptyTruck || _ctx.progressBar?.isFullTruck ))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Number of pallets')) + " ", 1),
                          _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.totalCartPallet) + " / " + _toDisplayString(_ctx.minimumOrderPallett || 20), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.messageType === 'mobile')
      ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
          _createElementVNode("div", _hoisted_43, [
            (!_ctx.isEmptyStandard)
              ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    (_ctx.progressBarMessage?.hasError)
                      ? (_openBlock(), _createBlock(_component_AllertCart, {
                          key: 0,
                          "height-value": 17,
                          "width-value": 14
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.progressBarMessage?.hasSucccess)
                      ? (_openBlock(), _createBlock(_component_SuccessCart, {
                          key: 1,
                          "height-value": 17,
                          "width-value": 14,
                          "is-white": true
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "fw-normal",
                      innerHTML: this.progressBarMiniMessage.title
                    }, null, 8, _hoisted_46)
                  ]),
                  _createVNode(_component_AsahiProgress, {
                    "is-white": _ctx.isWhite,
                    barHeight: "4",
                    percentage: _ctx.getFillPercentage(),
                    success: _ctx.progressBarMessage?.hasSucccess === true
                  }, null, 8, ["is-white", "percentage", "success"])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.messageType === 'long')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["asahi-progress-bar-message d-flex gap-1 align-items-start", _ctx.messageColorClass])
              }, [
                _createElementVNode("div", _hoisted_47, [
                  (_ctx.progressBarMessage?.hasError)
                    ? (_openBlock(), _createBlock(_component_AllertCart, {
                        key: 0,
                        "height-value": 24,
                        "width-value": 24
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.progressBarMessage?.hasSucccess)
                    ? (_openBlock(), _createBlock(_component_SuccessCart, {
                        key: 1,
                        "height-value": 24,
                        "width-value": 24
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    class: "d-flex align-items-center gap-1 fw-normal",
                    innerHTML: this.progressBarMessage.subtitle
                  }, null, 8, _hoisted_48),
                  _createElementVNode("div", _hoisted_49, [
                    (_ctx.progressBar?.isFullTruck)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Weight')) + " ", 1),
                          _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.progressBar?.iTotalCartWeight) + " Kg/ " + _toDisplayString(_ctx.progressBar?.iMaximumOrderWeight) + " Kg", 1)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.progressBar?.isFullTruck)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Monetary Value')) + " ", 1),
                          _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.formatCurrency(_ctx.grandTotalYourPrice)) + " / " + _toDisplayString(_ctx.formatCurrency(_ctx.maxOrderCash)), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.isWHSLUser && (_ctx.isEmptyTruck || _ctx.progressBar?.isFullTruck ))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Number of pallets')) + " ", 1),
                          _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.totalCartPallet) + " / " + _toDisplayString(_ctx.minimumOrderPallett || 20), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.messageType === 'compact')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["asahi-compact-container fs-6", {
      'border-default-text': !_ctx.isMobile && !(_ctx.progressBarMessage?.hasError || _ctx.progressBarMessage?.hasSucccess),
      'border-error-text': !_ctx.isMobile && _ctx.progressBarMessage?.hasError,
      'border-success-text': !_ctx.isMobile && _ctx.progressBarMessage?.hasSucccess
     }])
        }, [
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("div", null, [
              (_ctx.progressBarMessage?.hasError)
                ? (_openBlock(), _createBlock(_component_AllertCart, {
                    key: 0,
                    "height-value": 17,
                    "width-value": 14
                  }))
                : _createCommentVNode("", true),
              (_ctx.progressBarMessage?.hasSucccess)
                ? (_openBlock(), _createBlock(_component_SuccessCart, {
                    key: 1,
                    "height-value": 17,
                    "width-value": 14
                  }))
                : _createCommentVNode("", true)
            ]),
            (_ctx.messageType === 'compact' && !_ctx.isEmptyStandard)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "asahi-compact-message",
                  innerHTML: this.progressBarMessage.title
                }, null, 8, _hoisted_57))
              : _createCommentVNode("", true)
          ]),
          (_ctx.messageType === 'compact' && _ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "asahi-compact-message",
                innerHTML: this.progressBarMessage.mobileTitle
              }, null, 8, _hoisted_58))
            : _createCommentVNode("", true),
          _createVNode(_component_AsahiProgress, {
            "is-white": _ctx.isWhite,
            barHeight: "4",
            percentage: _ctx.getFillPercentage(),
            error: _ctx.progressBarMessage?.hasError === true && !_ctx.isMobile,
            success: _ctx.progressBarMessage?.hasSucccess === true && !_ctx.isMobile
          }, null, 8, ["is-white", "percentage", "error", "success"]),
          _createElementVNode("div", _hoisted_59, [
            (!_ctx.isMobile && !_ctx.isEmptyTruck)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "d-block",
                  innerHTML: this.progressBarMessage.subtitle
                }, null, 8, _hoisted_60))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_61, [
              (_ctx.isCompactAndFullTruck)
                ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Weight')) + " ", 1),
                    _createElementVNode("span", _hoisted_63, _toDisplayString(_ctx.progressBar?.iTotalCartWeight) + " / " + _toDisplayString(_ctx.progressBar?.iMinimumOrderWeight) + " Kg ", 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isCompactAndNotFullTruck && !_ctx.isEmptyTruck)
                ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Monetary Value')) + " ", 1),
                    _createElementVNode("span", _hoisted_65, _toDisplayString(_ctx.formatCurrency(_ctx.grandTotalYourPrice)) + " / " + _toDisplayString(_ctx.formatCurrency(_ctx.maxOrderCash)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isWHSLUser && (_ctx.isEmptyTruck || _ctx.progressBar?.isFullTruck ))
                ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Number of pallets')) + " ", 1),
                    _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.progressBar?.iTotalCartPalletQty) + " / " + _toDisplayString(_ctx.minimumOrderPallett || 20), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.messageType === 'mini')
      ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
          (!_ctx.isEmptyStandard)
            ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                _createElementVNode("div", _hoisted_70, [
                  _createElementVNode("div", null, [
                    (_ctx.progressBarMiniMessage?.hasError)
                      ? (_openBlock(), _createBlock(_component_AllertCart, {
                          key: 0,
                          "height-value": 17,
                          "width-value": 14
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.progressBarMiniMessage?.hasSucccess)
                      ? (_openBlock(), _createBlock(_component_SuccessCart, {
                          key: 1,
                          "height-value": 17,
                          "width-value": 14
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", {
                    class: "asahi-compact-message",
                    innerHTML: this.progressBarMiniMessage.title
                  }, null, 8, _hoisted_71)
                ]),
                _createVNode(_component_AsahiProgress, {
                  "is-white": _ctx.isWhite,
                  barHeight: "4",
                  percentage: _ctx.getFillPercentage()
                }, null, 8, ["is-white", "percentage"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.progressBarMessage?.hasError && _ctx.messageType === "icon-only")
      ? (_openBlock(), _createBlock(_component_AllertCart, {
          key: 4,
          "height-value": 17,
          style: {"padding-bottom":"5px"},
          "width-value": 14
        }))
      : _createCommentVNode("", true),
    (_ctx.progressBarMessage?.hasSucccess && _ctx.messageType === "icon-only")
      ? (_openBlock(), _createBlock(_component_SuccessCart, {
          key: 5,
          "height-value": 17,
          "width-value": 14,
          style: {"padding-bottom":"5px"},
          "is-white": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}