import { ActionTree, MutationTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { magnoliaServiceAPI } from '@/store/api-instance'
import { IRootState } from '@/store'
import {
  IFetchPortalHardCodeLinkRequest,
  IFetchPortalHardCodeLinkResponse,
  IFetchPortalPageMenuLinkRequest,
  IFetchPortalPageMenuLinkResponse,
  IPortalHardCodeLinkActions,
  IPortalHardCodeLinkMutations,
  IPortalHardCodeLinkState,
  IPortalPageMenuLinkActions,
  IPortalPageMenuLinkMutations,
  IPortalPageMenuLinkState,
  LinksActionType,
  LinksMutationType
} from './interfaces'

const portalState = (): IPortalHardCodeLinkState => ({
  portalHardCodeLink: {
    total: 0,
    offset: 0,
    limit: 0,
    results: []
  },
  search: '',
  loading: false
})

const portalPageMenuState = (): IPortalPageMenuLinkState => ({
  portalPageMenuLink: {
    items: []
  }
})

const pageMenuLinks = async (dto: IFetchPortalPageMenuLinkRequest): Promise<IFetchPortalPageMenuLinkResponse> => {
  const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchPortalPageMenuLinkResponse>('pagemenu', {
    params: dto
  })
  return response.data
}

const portalHardCodeLinkAPI = async (
  dto: IFetchPortalHardCodeLinkRequest
): Promise<IFetchPortalHardCodeLinkResponse> => {
  const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchPortalHardCodeLinkResponse>('portalExtLinks', {
    params: dto
  })
  return response.data
}

const mutations: MutationTree<IPortalHardCodeLinkState & IPortalPageMenuLinkState> &
  IPortalHardCodeLinkMutations &
  IPortalPageMenuLinkMutations = {
    [LinksMutationType.SET_LOADING] (
      state: IPortalHardCodeLinkState & IPortalPageMenuLinkState,
      payload: boolean
    ) {
      state.loading = payload
    },
    [LinksMutationType.SET_PORTAL_HARD_CODE_LINK] (
      state: IPortalHardCodeLinkState,
      payload: IFetchPortalHardCodeLinkResponse
    ) {
      state.portalHardCodeLink = payload
    },
    [LinksMutationType.SET_PAGE_MENU_LINK] (
      state: IPortalPageMenuLinkState,
      payload: IFetchPortalPageMenuLinkResponse
    ) {
      state.portalPageMenuLink = payload
    }
  }

const actions: ActionTree<IPortalHardCodeLinkState & IPortalPageMenuLinkState, IRootState> &
  IPortalHardCodeLinkActions &
  IPortalPageMenuLinkActions = {
    async [LinksActionType.FETCH_PORTAL_HARD_CODE_LINK] ({ commit }, dto: IFetchPortalHardCodeLinkRequest) {
      commit(LinksMutationType.SET_LOADING, true)
      const data = await portalHardCodeLinkAPI(dto)
      commit(LinksMutationType.SET_PORTAL_HARD_CODE_LINK, data)
      commit(LinksMutationType.SET_LOADING, false)
    },
    async [LinksActionType.FETCH_PAGE_MENU_LINK] ({ commit }, dto: IFetchPortalPageMenuLinkRequest) {
      commit(LinksMutationType.SET_LOADING, true)
      const data = await pageMenuLinks(dto)
      commit(LinksMutationType.SET_PAGE_MENU_LINK, data)
      commit(LinksMutationType.SET_LOADING, false)
    }
  }

export default {
  portalState,
  portalPageMenuState,
  mutations,
  actions
}
