import { ActionContext } from 'vuex'
import { IRootState } from '@/store'
import { IPortalHardCodeLink } from './IPortalHardCodeLink'

export enum LinksMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_PORTAL_HARD_CODE_LINK = 'SET_PORTAL_HARD_CODE_LINK',
  SET_PAGE_MENU_LINK = 'SET_PAGE_MENU_LINK'
}

export enum LinksActionType {
  FETCH_PORTAL_HARD_CODE_LINK = 'FETCH_PORTAL_HARD_CODE_LINK',
  FETCH_PAGE_MENU_LINK = 'FETCH_PAGE_MENU_LINK'
}

export interface IFetchPortalHardCodeLinkRequest {
  Limit?: number,
  Offset?: number,
  searchText?: string,
  isLoggedIn?: boolean,
  locationID?: string

}

export interface IFetchPortalPageMenuLinkRequest {
  Limit?: number,
  Offset?: number,
}

export interface IFetchPortalHardCodeLinkResponse {
  total: number
  offset: number
  limit: number
  results: IPortalHardCodeLink[]
}

export interface IFetchPortalPageMenuLinkResponse {
  items: []
}

export interface IPortalHardCodeLinkState {
  portalHardCodeLink: IFetchPortalHardCodeLinkResponse
  search: string
  loading: boolean
}

export interface IPortalPageMenuLinkState {
  portalPageMenuLink: IFetchPortalPageMenuLinkResponse
}

export interface IPortalHardCodeLinkMutations<S = IPortalHardCodeLinkState> {
  [LinksMutationType.SET_LOADING](state: S, payload: boolean): void

  [LinksMutationType.SET_PORTAL_HARD_CODE_LINK](state: S, payload: IFetchPortalHardCodeLinkResponse): void
}

export interface IPortalPageMenuLinkMutations<S = IPortalPageMenuLinkState> {
  [LinksMutationType.SET_LOADING](state: S, payload: boolean): void

  [LinksMutationType.SET_PAGE_MENU_LINK](state: S, payload: IFetchPortalPageMenuLinkResponse): void
}

export type AugmentedPortalHardCodeLinkActionType = {
  commit<K extends keyof IPortalHardCodeLinkMutations>(
    key: K,
    payload: Parameters<IPortalHardCodeLinkMutations[K]>[1]
  ): ReturnType<IPortalHardCodeLinkMutations[K]>
} & Omit<ActionContext<IPortalHardCodeLinkState, IRootState>, 'commit'>

export type AugmentedPortalPageMenuLinkActionType = {
  commit<K extends keyof IPortalPageMenuLinkMutations>(
    key: K,
    payload: Parameters<IPortalPageMenuLinkMutations[K]>[1]
  ): ReturnType<IPortalPageMenuLinkMutations[K]>
} & Omit<ActionContext<IPortalPageMenuLinkState, IRootState>, 'commit'>

export interface IPortalHardCodeLinkActions {
  [LinksActionType.FETCH_PORTAL_HARD_CODE_LINK](
    { commit }: AugmentedPortalHardCodeLinkActionType,
    payload: IFetchPortalHardCodeLinkRequest
  ): void
}
export interface IPortalPageMenuLinkActions {
  [LinksActionType.FETCH_PAGE_MENU_LINK](
    { commit }: AugmentedPortalPageMenuLinkActionType,
    payload: IFetchPortalPageMenuLinkRequest
  ): void
}
