
import { defineComponent, PropType } from 'vue'
import { mapGetters, mapState } from 'vuex'
import AsahiTabs from '@/components/library/AsahiTabs.vue'
import CustomButton from '@/components/library/AsahiBsCustomButton.vue'
import DropDown from '@/components/library/AsahiDropdown.vue'
import AsahiLogoNew from '@/components/library/assets/AsahiLogoNew.vue'

import AsahiUserIcon from '@/components/library/assets/AsahiUserIcon.vue'
import AsahiHistoryIcon from '@/components/library/assets/AsahiHistoryIcon.vue'
import AsahiSelect from '@/components/library/AsahiSelect.vue'
import locationwhite from '@/assets/icons/library/locationwhite.svg'
import locationgold from '@/assets/icons/library/locationgold.svg'
import AsahiFavoriteIcon from '@/components/library/assets/AsahiFavoriteIcon.vue'
import AsahiToast from '@/components/library/AsahiToast.vue'
import AsahiTypography from '@/components/library/Typography.vue'
import AsahiBsSearchProducts from '@/components/library/AsahiBsSearchProducts.vue'

import { FilesActionType } from '@/store/modules/files/interfaces/action-type'
import AsahiBsSearchMarketingMaterials from '@/components/library/AsahiBsSearchMarketingMaterials.vue'
import { ArticlesActionType } from '@/store/modules/articles/interfaces/action-type'
import AsahiBsSearchNewsAndArticles from '@/components/library/AsahiBsSearchNewsAndArticles.vue'
import { SupportActionType } from '@/store/modules/support/interfaces/action-type'
import AsahiBsSearchSupport from '@/components/library/AsahiBsSearchSupport.vue'
import AsahiLoaderCard from '@/components/library/AsahiLoaderCard.vue'
import chevronLeft from '@/assets/icons/library/chevron-left-gold.svg'
import { getValueByLabel, hasPermission } from '@/utils/permissions'
import { RolePermissionDetails } from '@/store/modules/users/interfaces/IUser'
import AsahiButton from '@/components/library/AsahiButton.vue'

interface HeaderData {
  label: string;
  children: any[];
  name: string;
  href?: string;
}
interface FooterLink {
  name: string,
  label: string
  href?: string
  link?: string
  target?: string
  isActive?: boolean
}
interface CartItem {
  id: string;
  product: {
    name: string;
    image: {
      url: string;
    };
    sku: number
    number_of_products_on_layer: number
  };
  quantity_total: number;
  number_of_layer_from_fe: number
  number_of_pallet_from_fe: number
  quantity_from_fe: number
  number_of_layer: number,
  number_of_pallet: number

}

interface User {
  permissionSchema: { shipToAddress: any, companyName: string, shipToId: string }[];
}

export default defineComponent({
  name: 'AsahiBsHeader',
  props: {
    headerData: {
      type: Object as PropType<HeaderData[]>,
      required: true
    },
    cartItem: {
      type: Array as PropType<CartItem[]>,
      required: true
    },
    favoriteItem: {
      type: Number,
      required: false
    },
    cartNumber: {
      type: Number
    },
    user: {
      type: Object as PropType<User>,
      required: true
    },
    catalogPermission: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('basket', ['id', 'query', 'items', 'paymentMethod', 'showToastDetails']),
    ...mapGetters('basket', ['progressBarMessage', 'progressBarSuccess', 'showProgressBarMessage']),
    ...mapState('catalog', ['products', 'alignment', 'shipTos']),
    ...mapGetters('main', ['getPermissions']),

    createOrderDropdown () {
      return this.headerData.find(d => d.name === 'Create an order')
    },

    marketingMaterialsFiles () {
      return this.filesStore.marketingMaterials.results
    },
    showToast () {
      return this.showToastDetails.show === true && this.showToastDetails.productName !== ''
    },
    showToastMessage () {
      return this.showToastDetails.actionType === 'add' ? this.$t('has been added to') : this.$t('has been updated.')
    },
    firstMessage () {
      return this.$t('Toast Add Product')
    },
    isTruckAvailable () {
      return this.query && this.query.progress_bar_data && typeof this.query.progress_bar_data.isFullTruck !== 'undefined'
    },

    isTruck () {
      if (this.isTruckAvailable) {
        return this.query.progress_bar_data.isFullTruck
      }
      return false
    },
    isTruckOnly (): boolean {
      return (this.query?.delivery_method === 'flatrate_flatrate' || localStorage.getItem('shippingMethod') === 'flatrate_flatrate')
    },
    getSelectedShipToDetails () {
      const shipToId = this.query.ship_to_id || localStorage.getItem('shipToId')
      const data = this.user.permissionSchema.filter((permission: any) => permission.shipToId === shipToId)
      return data
        ? {
            shipToId: data[0]?.shipToId,
            shipToName: data[0]?.companyName,
            shipToAddress: (data[0]?.shipToAddress)
          }
        : {}
    },
    shipToName () {
      const shipToId = this.query.ship_to_id || localStorage.getItem('shipToId')
      return this.user.permissionSchema.find((permission: any) => permission.shipToId === shipToId)
    },
    shipToList () {
      return this.user.permissionSchema.filter((permission: any) => permission.rpDetails.some((role: any) => role.permission.includes('Catalogue')))
        .map(item => ({
          label: item.companyName + ', ' + (item.shipToAddress)?.toLowerCase(),
          value: item.shipToId,
          text: item.companyName,
          longText: (item.shipToAddress)?.toLowerCase()
        }))
    },
    filesStore () {
      return this.$store.state.files
    },
    hasProductsData () {
      this.sendDataSearchProducts()
      return this.products.length > 0
    },
    hasMarketingMaterialsData () {
      const marketingMaterial = this.filesStore && this.filesStore.marketingMaterials && this.filesStore.marketingMaterials.results
      if (marketingMaterial && marketingMaterial.length > 0) {
        this.sendDataSearchMaterials()
      }
      return marketingMaterial && marketingMaterial.length > 0
    },
    hasNewsData () {
      const newArticle = this.$store.state.articles.searchArticles && this.$store.state.articles.searchArticles.results
      if (newArticle && newArticle.length > 0) {
        this.sendDataSearchNews()
      }
      return newArticle && newArticle.length > 0
    },
    hasSupportData () {
      const faqPages = this.$store.state.support.faqPages
      const kbsAsync = this.$store.state.support.newAllKbsAsync
      return (faqPages && faqPages.length > 0) || (kbsAsync && kbsAsync.length > 0)
    },
    visibleTabs () {
      return this.tabs.map(tab => {
        switch (tab.name) {
          case 'products':
            return { ...tab, isVisible: this.hasProductsData }
          case 'marketing-materials':
            return { ...tab, isVisible: this.hasMarketingMaterialsData }
          case 'news-and-articles':
            return { ...tab, isVisible: this.hasNewsData }
          case 'support':
            return { ...tab, isVisible: this.hasSupportData }
          default:
            return { ...tab, isVisible: true }
        }
      }).filter(tab => tab.isVisible)
    },
    customerType (): RolePermissionDetails | undefined {
      if (this.currentUser && this.currentUser?.permissionSchema?.length > 0) {
        return this.currentUser?.permissionSchema[0]?.rpDetails?.find((item: RolePermissionDetails) => item.customerType)
      }
      return undefined
    },
    currentUser () {
      return this.$store.state.auth.user
    },
    allPermission () {
      return this.currentUser.allAvailablePermission
    },
    userPermission () {
      return this.$store.state.auth.user
    },
    isWHSLUser () {
      const shipToIdFromStorage = this.shipTos[0]?.shiptosapid
      return this.userPermission?.permissionSchema?.some((company) =>
        company.shipToId === shipToIdFromStorage &&
        company.rpDetails[0].rolename.includes('WHS')
      )
    },
    isPOSUser () {
      return this.userPermission?.permissionSchema?.some((company) => company.rpDetails?.some((rp) => rp.customerType === 'POS' && this.currentUser.country === 'CZE'))
    }
  },
  data () {
    return {
      showOnHover: true,
      locationgold,
      locationwhite,
      openDropdown: false,
      searchActive: false,
      showModal: false,
      openCart: false,
      searchValue: '',
      searchKeyword: '',
      searchTimer: 0,
      searchTimerGTM: 0,
      searchTimerGTMMaterials: 0,
      searchTimerGTMNews: 0,
      CompanyName: localStorage.getItem('selectedCompanyName') || this.user.permissionSchema[0].companyName,
      tabs: [
        { name: 'products', label: this.$t('Products').toUpperCase(), component: AsahiBsSearchProducts },
        { name: 'marketing-materials', label: this.$t('Marketing materials').toUpperCase(), component: AsahiBsSearchMarketingMaterials },
        { name: 'news-and-articles', label: this.$t('News and Articles').toUpperCase(), component: AsahiBsSearchNewsAndArticles },
        { name: 'support', label: this.$t('Support').toUpperCase(), component: AsahiBsSearchSupport }
      ],
      currentTab: 0,
      idToken: sessionStorage.getItem('id_token'),
      searchHistory: JSON.parse(sessionStorage.getItem('search_history') as string),
      loading: false,
      chevronLeft,
      hideCta: false,
      showCtaCatalog: false,
      combinedMyprofile: [] as FooterLink[]
    }
  },
  components: {
    AsahiLoaderCard,
    AsahiFavoriteIcon,
    AsahiUserIcon,
    AsahiHistoryIcon,
    AsahiLogoNew,
    AsahiTabs,
    CustomButton,
    DropDown,
    AsahiSelect,
    AsahiToast,
    AsahiTypography,
    AsahiButton
  },
  methods: {
    navigateToPath (link: FooterLink) {
      this.$gtm.trackEvent({ ecommerce: null })
      this.$gtm.trackEvent({
        event: 'click_interaction',
        action: link.label,
        component: 'header'
      })

      this.$navigate(link.href || link.link || '')
    },
    sendDataSearchProducts () {
      if (this.searchTimerGTM) {
        clearTimeout(this.searchTimerGTM)
      }
      this.searchTimerGTM = setTimeout(() => {
        if ((this.searchKeyword !== '' || this.searchValue !== '') && this.products.length > 0) {
          this.$gtm.trackEvent({ ecommerce: null })
          this.$gtm.trackEvent({
            event: 'search_interaction',
            action: 'product_search',
            component: 'search',
            search_value: this.searchValue
          })
        }
      }, 2000) // 2000 ms (2 seconds) debounce delay
    },
    sendDataSearchMaterials () {
      if (this.searchTimerGTMMaterials) {
        clearTimeout(this.searchTimerGTMMaterials)
      }
      this.searchTimerGTMMaterials = setTimeout(() => {
        if ((this.searchKeyword !== '' || this.searchValue !== '')) {
          this.$gtm.trackEvent({ ecommerce: null })
          this.$gtm.trackEvent({
            event: 'search_interaction',
            action: 'materials_search',
            search_value: this.searchValue,
            component: 'search'
          })
        }
      }, 2000) // 2000 ms (2 seconds) debounce delay
    },
    sendDataSearchNews () {
      if (this.searchTimerGTMNews) {
        clearTimeout(this.searchTimerGTMNews)
      }
      this.searchTimerGTMNews = setTimeout(() => {
        if ((this.searchKeyword !== '' || this.searchValue !== '')) {
          this.$gtm.trackEvent({ ecommerce: null })
          this.$gtm.trackEvent({
            event: 'search_interaction',
            action: 'news_search',
            component: 'search',
            search_value: this.searchValue
          })
        }
      }, 2000) // 2000 ms (2 seconds) debounce delay
    },
    beginCheckout (url : string, actionValue: string) {
      this.$gtm.trackEvent({ ecommerce: null })
      this.$gtm.trackEvent({
        event: 'click_interaction',
        action: 'begin_checkout:' + actionValue,
        component: actionValue === 'go-to-cart' ? 'minicart' : 'header'
      })
      this.$navigate(url)
    },
    checkPermission (permission: string, value: Record<string, any>, additionalPremmision = true) {
      if (this.customerType) localStorage.setItem('customertype', this.customerType?.customerType)

      return this.allPermission?.includes(permission) &&
      additionalPremmision &&
      getValueByLabel(this.getPermissions(), permission)
        ? [value]
        : []
    },
    combinedOptions () {
      return [
        ...this.combinedMyprofile,
        {
          name: 'Log Out',
          label: this.$t('Log out'),
          href: '', // DO NOT ADD HREF HERE IT WILL MAKE PERFORMANCE ISSUE
          isActive: true
        }
      ]
    },
    myProfile () {
      const footerLinks: FooterLink[] = [

        {
          name: 'My Profile',
          label: this.$t('My Profile'),
          href: '/my-profile/details',
          isActive: true
        },
        {
          name: 'My Documents',
          label: this.$t('My Documents'),
          href: '/my-documents',
          isActive: true
        },
        {
          name: 'Reporting',
          label: this.$t('Reporting'),
          href: '/reporting',
          isActive: true
        },
        {
          name: 'Contracts',
          label: this.$t('Contracts'),
          href: '/contracts',
          isActive: true
        }

      ]

      // If the user is a POSUser, filter out 'Contracts' from the footer links.
      const filteredLinks = this.isPOSUser
        ? footerLinks.filter(link => link.name !== 'Contracts')
        : footerLinks
      this.combinedMyprofile = filteredLinks.filter((f) => {
        return hasPermission(this.allPermission, this.getPermissions(), f.name)
      })
    },
    async searchProducts (keyword?: string) {
      try {
        await this.$store.dispatch('catalog/FETCH_PRODUCTS', {
          search: this.searchValue?.length >= 3 ? this.searchValue : (keyword || ''),
          currentPage: 1,
          bypassloading: true
          // pageSize: 4
        }, { root: true })
      } catch (error) {
        console.error(error)
      }
    },
    searchMarketingMaterials (keyword?: string) {
      this.$store.dispatch(
        FilesActionType.FETCH_MATERIALS_SEARCH,
        {
          searchTextTitle: this.searchValue?.length >= 3 ? this.searchValue : (keyword || '')
        },
        { root: true }
      )
    },
    searchNewsAndArticles () {
      this.$store.dispatch(
        ArticlesActionType.SEARCH_ARTICLES,
        {
          searchTextTitle: this.searchValue,
          isLoggedIn: this.$store.state.auth.user.isLoggedIn
        },
        { root: true }
      )
    },
    searchSupport () {
      this.$store.dispatch(
        SupportActionType.FETCH_NEW_ALL_KBS_ASYNC,
        {
          searchTextTitle: this.searchValue
        },
        { root: true }
      )
      this.$store.dispatch(
        SupportActionType.FETCH_NEW_ALL_FAQ_PAGES,
        {
          searchTextTitle: this.searchValue
        },
        { root: true }
      )
    },
    storeSearchHistory () {
      const searchHistoryStored = JSON.parse(sessionStorage.getItem('search_history') as string) || []
      searchHistoryStored.unshift(this.searchValue)
      const newSearchHistory = [...new Set(searchHistoryStored)]?.slice(0, 3)
      this.searchHistory = newSearchHistory
      sessionStorage.setItem('search_history', JSON.stringify(newSearchHistory))
    },
    onSearchOpen () {
      if (!this.searchActive) {
        this.$gtm.trackEvent({
          event: 'click_interaction',
          action: 'open_click',
          component: 'search'
        })
        this.searchActive = true
        this.initSearch()
      }
    },
    onSearchClose () {
      this.searchActive = false
      this.onSearchReset()
    },
    initSearch () {
      if (this.searchHistory?.length > 0) {
        this.searchKeyword = this.searchHistory[0]
      } else {
        this.searchKeyword = ''
      }
      this.searchProducts(this.searchKeyword)
      this.searchMarketingMaterials(this.searchKeyword)
    },

    resetQuery () {
      const queries = {
        sort: { catalog_product_name: 'ASC' },
        search: '',
        category_uid: [],
        brand: [],
        sub_brand: [],
        filter_packaging: [],
        type: [],
        material_type: [],
        price: [],
        volume: []
      }
      this.$store.commit('catalog/SET_QUERIES', {
        ...queries
      }, { root: true })
    },

    resetSearch () {
      this.searchKeyword = ''
      this.resetQuery()
      this.searchProducts(this.searchKeyword)
      this.searchMarketingMaterials(this.searchKeyword)
    },
    doSearch () {
      const tabName = this.tabs[this.currentTab].name.toLowerCase().replace(' ', '-')
      if (tabName === 'products') {
        this.searchProducts()
      } else if (tabName === 'marketing-materials') {
        this.searchMarketingMaterials()
      } else if (tabName === 'news-and-articles') {
        this.searchNewsAndArticles()
      } else if (tabName === 'support') {
        this.searchSupport()
      }
    },
    async doSearchOnEnter () {
      this.loading = true
      await Promise.all([
        this.searchProducts(),
        this.searchMarketingMaterials(),
        this.searchNewsAndArticles(),
        this.searchSupport()
      ])
      this.loading = false
    },
    redoSearch (keyword: string) {
      this.searchValue = keyword
      this.doSearch()
    },
    onSearchType () {
      // Clear the previous timeout if it's set
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }

      // Set a new timeout
      this.searchTimer = setTimeout(() => {
        if (this.searchValue.length >= 3) {
          this.storeSearchHistory()
          this.searchProducts()
          this.searchMarketingMaterials()
          this.searchNewsAndArticles()
          this.searchSupport()
        } else {
          this.initSearch()
        }
      }, 2000) // 2000 ms (2 seconds) debounce delay
    },
    onSearchEnter () {
      clearTimeout(this.searchTimer)
      this.storeSearchHistory()
      this.doSearch()
    },
    onSearchReset () {
      this.searchValue = ''
      this.resetSearch()
    },
    onTabChanged (tabIndex: number) {
      this.currentTab = tabIndex
      this.checkAndUpdateTab(tabIndex)
    },
    checkAndUpdateTab (tabIndex: number) {
      const visibleTabs = this.visibleTabs
      if (!visibleTabs.length) return

      // Check if the current tab is visible
      const isCurrentTabVisible = visibleTabs.some((tab, index) => index === tabIndex)

      if (!isCurrentTabVisible) {
      // Find the first visible tab
        const newTabIndex = visibleTabs.length ? 0 : -1
        this.currentTab = newTabIndex
      }
    },
    toggleModal () {
      this.showModal = !this.showModal
    },
    toggleDropdown () {
      this.openDropdown = !this.openDropdown
    },
    toggleOpenCart () {
      this.openCart = !this.openCart
    },
    async removeProductFromCart (productId: string) {
      try {
        const store = this.$store // Assicurati che lo store sia corretto
        if (store.state.basket?.id && productId) {
          await store.dispatch('basket/REMOVE_ITEM', {
            cart_id: store.state.basket.id,
            cart_item_id: productId
          }, { root: true })
        }
      } catch (error) {
        console.error('Failed to remove product from cart:', error)
      }
    },
    queries () {
      return this.$store.state.catalog.queries
    },
    updateSearchValue (value: string) {
      const query = {
        ...this.queries,
        search: value
      }
      this.$store.commit('catalog/SET_QUERIES', query, { root: true })
      this.$store.dispatch('catalog/FETCH_PRODUCTS', query, { root: true })
    },
    async removeItemInner (id: string) {
      await this.$store.dispatch('basket/REMOVE_ITEM', { cart_id: this.id, cart_item_id: id }, { root: true })
    },
    async removeAllCartItems () {
      this.$gtm.trackEvent({ ecommerce: null })
      this.$gtm.trackEvent({
        event: 'remove_from_cart',
        action: 'remove-all-product',
        component: 'minicart',
        ecommerce: {
          value: '',
          currency: 'CZK',
          items: this.items.map((item: any) => {
            return {
              item_id: item.product.sku,
              item_name: item.product.name,
              quantity: item.quantity
            }
          })
        }
      })
      await Promise.all(this.items?.map((product: any) => this.removeItemInner(product.id)))
    },
    updateCompanyName (selectedCompanyName: any) {
      this.CompanyName = selectedCompanyName
      localStorage.setItem('selectedCompanyName', selectedCompanyName)
    },
    hideToast () {
      this.$store.commit('basket/SET_TOAST_NOTIFICATION_DETAILS', { show: false, productName: '' }, { root: true })
    },
    goBack () {
      history.back()
    },
    getRoutingPath (children: any, name: string) {
      let routingPath = ''
      if (name === 'products_catalogue') {
        routingPath = '/catalog/products'
      } else {
        routingPath = children.length > 0 ? children[0].href : ''
      }
      return routingPath
    },

    navigateToProductPage (item: any) {
      this.$gtm.trackEvent({
        event: 'select_item',
        action: item.name,
        component: 'search',
        ecoomerce: {
          value: '',
          currency: 'CZK',
          items: [{
            item_id: item.sku,
            item_name: item.name,
            item_tag_last: item.product_last === 1,
            item_tag_new: item.product_new === 1,
            item_tag_recommended: item.product_recommended === 1,
            item_tag_sale: item.product_sale === 1,
            volume: item.volume,
            price: item.your_price_tax
          }]
        }
      })
      this.$navigate(`/catalog/${item.sku}`)
      this.onSearchClose()
    }
  },
  mounted () {
    this.myProfile()
    this.combinedOptions()
    this.$store.commit('basket/SET_PROGRESS_BAR', this.progressBarMessage)
  },
  watch: {
    $route: {
      handler (nextVal) {
        this.hideCta = nextVal?.meta?.hideCreateOrderButton as boolean || false
        this.showCtaCatalog = nextVal?.meta?.showBackToCatalog as boolean || false
        this.myProfile()
        this.combinedOptions()
        console.log(this.combinedOptions())
      },
      immediate: true,
      deep: true
    },
    '$i18n.locale': {
      handler () {
        this.myProfile()
      }
    },
    visibleTabs () {
      this.checkAndUpdateTab(this.currentTab)
    }
  }
})
