import gql from 'graphql-tag'

export const fetchProductsQuery = gql(`
  query (
    $sku: [String]
    $search: String
    $sort: ProductAttributeSortInput
    $brand: [String]
    $category_id: [String]
    $category_uid: [String]
    $filter_packaging: [String]
    $packaging: [String]
    $sub_brand: [String]
    $volume: [String]
    $currentPage: Int!
    $pageSize: Int = 25
    $is_peroni: Boolean = false
    $product_last: String
    $product_new: String
    $product_sale: String
    $product_recommended: String
    $product_type: String
  ) {
    products(
      search: $search
      filter: {
        sku: { in: $sku }
        type_id: {eq: $product_type}
        brand: { in: $brand }
        sub_brand: { in: $sub_brand }
        category_id: { in: $category_id }
        category_uid: { in: $category_uid }
        filter_packaging: { in: $filter_packaging }
        packaging: { in: $packaging }
        volume: { in: $volume }
        product_last: { eq: $product_last }
        product_new: { eq: $product_new }
        product_sale: { eq: $product_sale }
        product_recommended: { eq: $product_recommended }
      }
      pageSize: $pageSize
      currentPage: $currentPage
      sort: $sort
    ) {
      total_count
      categoryFilter {
        category_name
        category_uid
        category_id
      }
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
      items {
        multi_deposit
        material_type
        number_of_items_in_box
        name
        catalog_product_name
        deliveryDate
        sku
        your_price
        your_price_tax
        favourites
        promo
        product_last
        product_new
        product_sale
        product_recommended
        number_of_products_on_layer
        number_of_items_on_pallet
        number_of_layers_per_pallet
        isavailable_truck_package_ordering
        volume
        saleability {
          next_date
          sellable
        }
        description {
          html
        }
        promo_price_leaflet {
          promo_price
          promo_price_percentage_with_tax
          promo_price_percentage_wo_tax
          promo_price_is_inside_delivery
          promo_price_valid_from
          promo_price_valid_to
          long_term_discount
          long_term_discount_percentage_with_tax
          long_term_discount_percentage_wo_tax
          base_price
          percentage
        }
        features @include(if: $is_peroni) {
          tipo_birra
          provenienza
          gradazione_alcolica
          termine_conservazione
        }
        small_image {
          url
        }
        cart_fields {
          packaging_cart
          layer_cart
          pallets_cart
        }
        ... on BundleProduct {
          your_price_sum
          your_price_sum_tax
          bundle_price_sum
          bundle_price_sum_tax
          bundle_fields {
            minimum_amt
            valid_from
            valid_to
            limited_amount
          }
          items {
            option_id
            title
            sku
            type
            required
            position
            your_price_sum
            your_price_sum_tax
            options {
              uid
              id
              label
              quantity
              can_change_quantity
              price
              price_type
              is_default
              position
              product {
                id
                name
                catalog_product_name
                name
                sku
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                  }
                }
                your_price
                your_price_tax
              }
            }
          }
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`)
