
import { defineComponent } from 'vue'
import AsahiButton from '@/components/library/AsahiButton.vue'
import AsahiTypography from '@/components/library/Typography.vue'

export default defineComponent({
  name: 'MessageModal',
  components: {
    AsahiButton,
    AsahiTypography
  },
  props: {
    message: {
      type: String,
      required: true
    },
    subMessage: {
      type: String,
      default: '',
      required: false
    },
    addSubMessage: {
      type: String,
      default: '',
      required: false
    },
    footerMessage: {
      type: String,
      default: '',
      required: false
    },
    buttonText: {
      type: String,
      required: false,
      default: 'OK'
    },
    isErrorModal: {
      type: Boolean,
      required: false,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    },
    hyperLink: {
      type: String,
      default: '',
      required: false
    },
    hyperLinkText: {
      type: String,
      required: false,
      default: ''

    },
    headerSubMsg: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: false,
      default: '500px'
    },
    height: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['close'],

  methods: {
    close () {
      this.$emit('close')
    }
  }
})
