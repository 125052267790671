
import { defineComponent, PropType } from 'vue'
import AsahiSelectBox from '@/components/library/AsahiSelectBox.vue'

export default defineComponent({
  name: 'AsahiTabs',
  components: {
    AsahiSelectBox
  },
  props: {
    tabs: {
      type: Array as PropType<Array<{ name: string, label: string, component: string, counter: number, icon: any }>>,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    currentTab: {
      type: Number,
      default: 0,
      required: true
    },
    searchValue: {
      type: String
    },
    activeColor: {
      type: String,
      default: ''
    },
    inactiveColorClass: {
      type: String,
      default: ''
    },
    displayType: {
      type: String,
      default: 'tab'
    },
    pdpTabs: {
      type: Boolean,
      default: false
    }
  },
  emits: ['emitChildMethod', 'tabChanged'],
  data () {
    return {
      internalCurrentTab: this.currentTab
    }
  },
  computed: {
    dynamicClassName () {
      return `asahi-tabs-container ${this.className}`
    },
    currentTabComponent () {
      const tab = this.tabs[this.internalCurrentTab]
      return tab ? tab.component : null
    },
    tabListData () {
      return this.tabs.map((t, i: number) => {
        const item = {
          label: t.label,
          value: i,
          icon: t.icon
        }
        return item
      })
    },
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  watch: {
    currentTab (newVal) {
      this.internalCurrentTab = newVal
    }
  },
  methods: {
    handleTabClick (index :number) {
      if (this.internalCurrentTab !== index) {
        this.internalCurrentTab = index
        this.$emit('tabChanged', index)
      }
    },
    emitChildMethod (value: any) {
      this.$emit('emitChildMethod', value)
    }
  }
})
