import gql from 'graphql-tag'

export const fetchRecommendedListQuery = gql(`
query ($shipTo: String, $selectedItem: String, $cartItems: [CartItemsInput]) {
  recommendItems(
    shipTo: $shipTo
    selectedItem: $selectedItem
    cartItems: $cartItems
  ) {
    shipTo
    recItems {
      productId
      Priority
      multi_deposit
      name
      catalog_product_name
      material_type
      number_of_items_in_box
      your_price
      your_price_tax
      favourites
      product_last
      product_new
      product_sale
      product_recommended
      number_of_products_on_layer
      number_of_items_on_pallet,
      number_of_layers_per_pallet
      isavailable_truck_package_ordering
      small_image
      promo_price
      promo_price_percentage_with_tax
      promo_price_percentage_wo_tax
      promo_price_is_inside_delivery
      promo_price_valid_from
      promo_price_valid_to
      long_term_discount
      long_term_discount_percentage_with_tax
      long_term_discount_percentage_wo_tax
      base_price
      percentage
      cart_fields {
        packaging_cart
        layer_cart
        pallets_cart
      }
      __typename
    }
  }
}
`)
