<template>
  <svg
    :class="{ rotated: isRotated }"
    width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 10L9.5 2L17.5 10" :stroke="arrowColor" stroke-width="2"/>
  </svg>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'AsahiArrow',
  props: {
    isRotated: {
      type: Boolean
    },
    arrowColor: {
      type: String,
      default: 'black'
    }
  },
  setup () {
    const isRotated = ref(false)

    const toggleRotation = () => {
      isRotated.value = !isRotated.value
    }

    return {
      toggleRotation
    }
  }

}
</script>

<style scoped lang='scss'>
svg {
  width: 15px;
  height: auto;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.rotated {
  transform: rotate(180deg);
}
</style>
